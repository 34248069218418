@include font-face(300, 'OpenSans');
@include font-face(300, 'OpenSans', 'italic');
@include font-face(400, 'OpenSans');
@include font-face(400, 'OpenSans', 'italic');
@include font-face(600, 'OpenSans');
@include font-face(600, 'OpenSans', 'italic');
@include font-face(700, 'OpenSans');
@include font-face(700, 'OpenSans', 'italic');
@include font-face(800, 'OpenSans');
@include font-face(800, 'OpenSans', 'italic');

@include font-face(100, 'BanderaPro');
@include font-face(100, 'BanderaPro', 'italic');
@include font-face(300, 'BanderaPro');
@include font-face(300, 'BanderaPro', 'italic');
@include font-face(400, 'BanderaPro');
@include font-face(400, 'BanderaPro', 'italic');
@include font-face(500, 'BanderaPro');
@include font-face(500, 'BanderaPro', 'italic');
@include font-face(700, 'BanderaPro');
@include font-face(700, 'BanderaPro', 'italic');
@include font-face(900, 'BanderaPro');
@include font-face(900, 'BanderaPro', 'italic');
